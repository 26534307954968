import ZE from './ZE.png'
import * as React from 'react';
import Professional from './Professional.png'
import Wheel from './wheel.png'


import {motion} from 'framer-motion'




import Aos from 'aos'
import "aos/dist/aos.css"
import s from './s.png'






import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';


import { CSSPlugin } from "gsap/CSSPlugin"; // Import CSSPlugin









import Rss from './Rss.png'
import RJelly from './RJelly.png'
import RSigma from './RSigma.png'
import RX from './RX.png'
import { Canvas, useFrame } from '@react-three/fiber';
import { PresentationControls, useGLTF } from '@react-three/drei';
import {Model as Meatride } from './Meatride.jsx'

import Dior from './dior.glb'
import chanel from './chanel.glb'

import {Model as Emperio} from './Emperio.jsx'
import Merc from './merc.glb'
import horse from './headless.glb'
import war from './war.glb'

import Zont from './Zont.mp4'
import vo from './vo.mp4'
import finger from './finger.mp4'
import {Model as Men} from './Men.jsx'
import {Model as Female} from './Female.jsx'

import { scroll } from "framer-motion/dom";
gsap.registerPlugin(ScrollTrigger, CSSPlugin);
















function App() {
  

 




  function Chanel(props) {
    const { scene, camera } = useGLTF(chanel);
    const mesh = React.useRef();
    let rotationSpeed = -0.015;
 
    useFrame(() => {
      // Rotate the mesh (car) continuously
      mesh.current.rotation.y += rotationSpeed; // Use the rotation speed defined in useEffect
    });
 
    return <primitive object={scene} {...props} ref={mesh} />;
  }
 
 


  function DioDio(props) {
    const { scene, camera } = useGLTF(Dior);
    const mesh = React.useRef();
    let rotationSpeed = 0.015;
 
    useFrame(() => {
      // Rotate the mesh (car) continuously
      mesh.current.rotation.y += rotationSpeed; // Use the rotation speed defined in useEffect
    });
 
    return <primitive object={scene} {...props} ref={mesh} />;
  }
 
 








  function Mercl(props) {
    const { scene, camera } = useGLTF(Merc);
    const mesh = React.useRef();
    let rotationSpeed = 0.005;


    React.useEffect(() => {
      // Define the default rotation speed
 
      // Update rotation speed based on scroll position
      const handleScroll = () => {
        if (window.scrollY > window.innerHeight * 1.75) {
          rotationSpeed = 0.009;
        } else {
          rotationSpeed = 0.002;
        }
      };
 
      // Add event listener for scroll
      window.addEventListener('scroll', handleScroll);
 
      // Cleanup
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []); // Only runs once on component mount
 
    useFrame(() => {
      // Rotate the mesh (car) continuously
      mesh.current.rotation.y += rotationSpeed; // Use the rotation speed defined in useEffect
    });
 
    return <primitive object={scene} {...props} ref={mesh} />;
  }
 
 





  function Horse(props) {
    const { scene, camera } = useGLTF(horse);
    const mesh = React.useRef();
    let rotationSpeed = 0.005;


    React.useEffect(() => {
      // Define the default rotation speed
 
      // Update rotation speed based on scroll position
      const handleScroll = () => {
        if (window.scrollY > window.innerHeight * 1.75) {
          rotationSpeed = 0.009;
        } else {
          rotationSpeed = 0.002;
        }
      };
 
      // Add event listener for scroll
      window.addEventListener('scroll', handleScroll);
 
      // Cleanup
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []); // Only runs once on component mount
 
    return <primitive object={scene} {...props} ref={mesh} />;
  }
 




  function War(props) {
    const { scene, camera } = useGLTF(war);
    const mesh = React.useRef();
    let rotationSpeed = 0.005;


    React.useEffect(() => {
      // Define the default rotation speed
 
      // Update rotation speed based on scroll position
      const handleScroll = () => {
        if (window.scrollY > window.innerHeight * 1.75) {
          rotationSpeed = 0.009;
        } else {
          rotationSpeed = 0.002;
        }
      };
 
      // Add event listener for scroll
      window.addEventListener('scroll', handleScroll);
 
      // Cleanup
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []); // Only runs once on component mount
 
    useFrame(() => {
      // Rotate the mesh (car) continuously
      mesh.current.rotation.y += rotationSpeed; // Use the rotation speed defined in useEffect
    });
 
    return <primitive object={scene} {...props} ref={mesh} />;
  }


 


 
  const bout = React.useRef();


  React.useEffect(() => {
    const animation = gsap.from(bout.current, {
      color: 'blue',
      opacity: 0,
      duration: 0.5, // Adjust the duration as needed


      scrollTrigger: {
        trigger: bout.current,
        start: '+=-120px',
        end: '+=140px',
        scrub: 1,
        onUpdate: (self) => {
          // Animation onUpdate logic, if needed
        },
      },
    });


    return () => {
      animation.kill();
    };
  }, []); // Empty dependency array to run the effect only once after initial render
 
  React.useLayoutEffect (() => {
    Aos.init({duration:2000})
  },[])








  const ing = React.useRef();


  React.useLayoutEffect(() => {


    gsap.set(ing.current, { opacity:0});
 
    const animation = gsap.to(ing.current, {
      opacity:1,
            ease: "power3.inOut",
            duration:1,
            delay:2,
      scrollTrigger: {
        start: "+=3750px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4400px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    return () => {
      animation.kill();
      
    };
  }, []);
 































  const lefte = React.useRef();


  React.useEffect(() => {


    gsap.from(lefte.current, {opacity:1});
 
    const animation = gsap.to(lefte.current, {
    opacity:0,
      scrollTrigger: {
        start: "+=1100px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=1300px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);






























  const smoke = React.useRef();


  React.useEffect(() => {


    gsap.set(smoke.current, {background: 'linear-gradient(to right,blue,purple)',opacity:1});
 
    const animation = gsap.to(smoke.current, {
      background: 'linear-gradient(to right,purple,rgb(199, 21, 133))',
            ease: "power3.inOut",
            duration:2,
      scrollTrigger: {
        start: "+=1100px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=1300px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 0.6,
      },
    });
 
    gsap.to(smoke.current, {
      background: 'grey',
            ease: "power3.inOut",
            scrollTrigger: {
              start: "+=6550px", // Start the animation when the trigger is at the vertical center of the viewport
              end: '+=100px', // End the animation when the trigger is 20% from the bottom of the viewport
              scrub: 1,
            },
          });
          gsap.to(smoke.current, {
            opacity:0,
                  ease: "power3.inOut",
                  scrollTrigger: {
                    start: "+=9550px", // Start the animation when the trigger is at the vertical center of the viewport
                    end: '+=550px', // End the animation when the trigger is 20% from the bottom of the viewport
                    scrub: 1,
                  },
                });
    return () => {
      animation.kill();
    };
  }, []);
 













  


  const emperious = React.useRef();


  React.useEffect(() => {


    gsap.from(emperious.current, { opacity:1,   y:-400,    filter: 'blur(0px)',  });
 
    const animation = gsap.to(emperious.current, {
      opacity:0.4,
       filter: 'blur(5px)',
                  ease: "power3.inOut",
            duration:3,
      scrollTrigger: {
        start: "+=6150px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=400px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    gsap.to(emperious.current, {
      y:0,
                  ease: "power3.inOut",
            duration:3,
      scrollTrigger: {
        start: "+=5700px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=1400px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);










  const moust = React.useRef();


  React.useEffect(() => {


    gsap.from(moust.current, {opacity:1});
 
    const animation = gsap.to(moust.current, {
      opacity:0,
            ease: "power3.inOut",
            duration:3,
      scrollTrigger: {
        start: "+=100px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=200px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });



  }, []);























  const inst = React.useRef();


  React.useEffect(() => {


    gsap.from(inst.current, {color:'blue',opacity:1});
 
    const animation = gsap.to(inst.current, {
      color:'rgb(199, 21, 133)',
            ease: "power3.inOut",
            duration:3,
      scrollTrigger: {
        start: "+=1100px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=1300px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
    gsap.to(inst.current, {
      color:'white',
            ease: "power3.inOut",
            scrollTrigger: {
              start: "+=6550px", // Start the animation when the trigger is at the vertical center of the viewport
              end: '+=50px', // End the animation when the trigger is 20% from the bottom of the viewport
              scrub: 1,
            },
          });
          gsap.to(inst.current, {
            opacity:0,
                  ease: "power3.inOut",
                  scrollTrigger: {
                    start: "+=9550px", // Start the animation when the trigger is at the vertical center of the viewport
                    end: '+=550px', // End the animation when the trigger is 20% from the bottom of the viewport
                    scrub: 1,
                  },
                });
 
    return () => {
      animation.kill();
    };
  }, []);








  const whats = React.useRef();


  React.useEffect(() => {


    gsap.from(whats.current, {color:'blue',  opacity:1,  });
 
    const animation = gsap.to(whats.current, {
      color:'rgb(199, 21, 133)',
            ease: "power3.inOut",
            duration:3,
      scrollTrigger: {
        start: "+=1100px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=1300px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });

    gsap.to(whats.current, {
color:'white',
      ease: "power3.inOut",
      scrollTrigger: {
        start: "+=6550px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=50px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
    gsap.to(whats.current, {
      opacity:0,
            ease: "power3.inOut",
            scrollTrigger: {
              start: "+=9550px", // Start the animation when the trigger is at the vertical center of the viewport
              end: '+=550px', // End the animation when the trigger is 20% from the bottom of the viewport
              scrub: 1,
            },
          });
 
    return () => {
      animation.kill();
    };
  }, []);












  const champ1 = React.useRef();
  const champ2 = React.useRef();


























  const champ = React.useRef();


  React.useEffect(() => {


    gsap.from(champ.current, { WebkitTextFillColor: 'blue',WebkitTextStroke: '0.5px blueviolet', opacity:1,  });
 
    const animation = gsap.to(champ.current, {
      opacity:0.2,
      WebkitTextFillColor: 'red',WebkitTextStroke: '0.5px blueviolet',    
      ease: "power3.inOut",
      scrollTrigger: {
        start: "+=1100px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=1200px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });

    gsap.to(champ.current, {
      WebkitTextStroke: '0.5px black',    
      WebkitTextFillColor: 'white',
      ease: "power3.inOut",
      scrollTrigger: {
        start: "+=6550px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=50px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    gsap.to(champ.current, {
display:'none',
      ease: "power3.inOut",
      scrollTrigger: {
        start: "+=9550px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=550px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
    return () => {
      animation.kill();
    };
  }, []);






  React.useEffect(() => {


    gsap.from(champ1.current, { WebkitTextFillColor: 'blue',WebkitTextStroke: '0.5px blueviolet', opacity:0.2, });
 
    const animation = gsap.to(champ1.current, {
      opacity: 1,
      WebkitTextFillColor: 'red',WebkitTextStroke: '0.5px blueviolet',    
      scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ? "+=1000px": "+=1200px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=1300px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
    gsap.to(champ1.current, {
      WebkitTextStroke: '0.5px black',          opacity: 1,

      WebkitTextFillColor: 'white',
      ease: "power3.inOut",
      scrollTrigger: {
        start: "+=6550px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=50px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
    gsap.to(champ1.current, {
      display:'none',            
      ease: "power3.inOut",
            scrollTrigger: {
              start: "+=9550px", // Start the animation when the trigger is at the vertical center of the viewport
              end: '+=550px', // End the animation when the trigger is 20% from the bottom of the viewport
              scrub: 1,
            },
          });
    return () => {
      animation.kill();
    };
  }, []);





  React.useEffect(() => {


    gsap.from(champ2.current, { WebkitTextFillColor: 'blue',WebkitTextStroke: '0.5px blueviolet',    });
 
    const animation = gsap.to(champ2.current, {
      WebkitTextFillColor: 'red',WebkitTextStroke: '0.5px blueviolet',    
      scrollTrigger: {
        start: "+=1300px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=1400px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
    gsap.to(champ2.current, {
      WebkitTextStroke: '0.5px black',    
      WebkitTextFillColor: 'white',
      ease: "power3.inOut",
      scrollTrigger: {
        start: "+=6550px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=50px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
    gsap.to(champ2.current, {
      opacity:0,
            ease: "power3.inOut",
            scrollTrigger: {
              start: "+=9550px", // Start the animation when the trigger is at the vertical center of the viewport
              end: '+=550px', // End the animation when the trigger is 20% from the bottom of the viewport
              scrub: 1,
            },
          });
 
    return () => {
      animation.kill();
    };
  }, []);







const magnif = React.useRef()




  React.useEffect(() => {


    gsap.from(magnif.current, {y:0, x:0 });
 
    const animation = gsap.to(magnif.current, {
      yPercent:window.innerHeight >= 700 && window.innerHeight <= 800 ? 1700: 1325,
      ease: 'none',
            duration:13,
            
      scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"+=3580px": window.innerHeight >= 700 && window.innerHeight <= 800 ? "+=3350px":"+=3533px", // Start the animation when the trigger is at the vertical center of the viewport
        end: window.innerHeight >= 700 && window.innerHeight <= 800 ? "+=2400px": '+=2400px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: true,
        pin: true,
        invalidateOnRefresh: true,
        anticipatePin: 1
      },
    });
    gsap.to(magnif.current, {
      color:'black',
      ease: 'none',
            duration:13,
            
      scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"+=3740px": window.innerHeight >= 700 && window.innerHeight <= 800 ?"+=3540px":"+=3633px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=350px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: true,
        pin: true,
        invalidateOnRefresh: true,
        anticipatePin: 1
      },
    });


    gsap.to(magnif.current, {
      xPercent: -100, 
      ease: 'none',
            duration:13,
      scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"+=3740px": window.innerHeight >= 700 && window.innerHeight <= 800 ? "+=2500px":"+=3530px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4300px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: true,
        pin: true,
        invalidateOnRefresh: true,
        anticipatePin: 1
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);
















  const bdc = React.useRef()

  React.useEffect(() => {


    gsap.from(bdc.current, {y:0, x:0 });
 
    const animation = gsap.to(bdc.current, {
      yPercent:320,
      ease: 'none',
            duration:13,
            
      scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"+=3740px": window.innerHeight >= 700 && window.innerHeight <= 800 ?"+=3100px":"+=3433px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=3100px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: true,
        pin: true,
        invalidateOnRefresh: true,
        anticipatePin: 1
      },
    });
    gsap.to(bdc.current, {
      xPercent: -250, 
      ease: 'none',
            duration:13,
      scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"+=3740px":"+=3630px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4500px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: true,
        pin: true,
        invalidateOnRefresh: true,
        anticipatePin: 1
      },
    });


    return () => {
      animation.kill();
    };
  }, []);
























const back = React.useRef()


React.useEffect(() => {


  gsap.from(back.current, {background:'rgb(4, 4, 4)'});

  const animation = gsap.to(back.current, {
background:'white',
  
    ease: 'none',
          duration:2,
          
    scrollTrigger: {
      start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"+=3340px": window.innerHeight >= 700 && window.innerHeight <= 800 ? "+=3500px": "+=3803px", // Start the animation when the trigger is at the vertical center of the viewport
      end: '+=100px', // End the animation when the trigger is 20% from the bottom of the viewport
      scrub: true,
      pin: true,
      invalidateOnRefresh: true,
      anticipatePin: 1,
    },
  });

  return () => {
    animation.kill();
  };
}, []);



























const savage = React.useRef()

  React.useEffect(() => {


    gsap.from(savage.current, {y:0, x:0 });
 
    const animation = gsap.to(savage.current, {
      rotate:260,
      yPercent:355,
    
      ease: 'none',
            duration:2,
            
      scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"+=3640px":window.innerHeight >= 700 && window.innerHeight <= 800 ? "+=3200px": "+=3663px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=2000px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: true,
        pin: true,
        invalidateOnRefresh: true,
        anticipatePin: 1
      },
    });
    gsap.to(savage.current, {
      xPercent: -100, 
      ease: 'none',
            duration:13,
      scrollTrigger: {
        start: window.innerWidth >= 1000 && savage.innerWidth <= 1325 ?"+=3540px":"+=3530px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4300px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: true,
        pin: true,
        invalidateOnRefresh: true,
        anticipatePin: 1
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);










  const words = React.useRef();
  const dayt = React.useRef();






  React.useEffect(() => {


    gsap.from(words.current, {y:0, WebkitTextFillColor: 'blue',WebkitTextStroke: '1px hotpink',  });
 
    const animation = gsap.to(words.current, {
      WebkitTextFillColor: 'purple',WebkitTextStroke: '2px red',
     y:1300,
      ease: "power3.inOut",
      duration:13,
      scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"-140px": window.innerHeight >= 700 && window.innerHeight <= 800 ?"+=-30px":"+=120px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4000px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);














  const square = React.useRef();


  React.useEffect(() => {


    gsap.from(square.current, {y:0, rotate:0});
 
    const animation = gsap.to(square.current, {
     y:1500,
      ease: "power3.inOut",
     scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"-140px":window.innerHeight >= 700 && window.innerHeight <= 800 ?"+=-30px":"+=120px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4000px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    gsap.to(square.current, {
      rotate:360,
       ease: "power3.inOut",
      scrollTrigger: {
         start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"-140px": "+=820px", // Start the animation when the trigger is at the vertical center of the viewport
         end: '+=2400px', // End the animation when the trigger is 20% from the bottom of the viewport
         scrub: 1,
       },
     });
    return () => {
      animation.kill();
    };
  }, []);








  const line = React.useRef();


  React.useEffect(() => {


    gsap.from(line.current, {y:0, background:'linear-gradient(rgb(60, 0, 109),black)'});
 
    const animation = gsap.to(line.current, {
     y:1300,background:'linear-gradient(red,black)',
      ease: "power3.inOut",
      duration:5,
     scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"-140px":window.innerHeight >= 700 && window.innerHeight <= 800 ?"+=-30px": "+=120px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4000px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);


















  const words1 = React.useRef();


  React.useEffect(() => {


    gsap.from(words1.current, {y:0,WebkitTextFillColor: 'black',WebkitTextStroke: '1px blueviolet', });
 
    const animation = gsap.to(words1.current, {
      WebkitTextFillColor: 'red',
WebkitTextStroke: '2px purple',
     y:1300,
      ease: "power3.inOut",
      duration:5,
     scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"-140px":window.innerHeight >= 700 && window.innerHeight <= 800 ?"+=-30px": "+=120px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4000px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);






  const words2 = React.useRef();


  React.useEffect(() => {


    gsap.from(words2.current, {y:0,WebkitTextFillColor: 'black',WebkitTextStroke: '1px blueviolet', rotateY:0 });
 
    const animation = gsap.to(words2.current, {
      rotateY:360,
     y:1300,
      ease: "power3.inOut",
      duration:5,
     scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"-140px": window.innerHeight >= 700 && window.innerHeight <= 800 ?"+=-30px":"+=120px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4000px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);


















  const words3 = React.useRef();


  React.useEffect(() => {


    gsap.from(words3.current, {y:0,WebkitTextFillColor: 'black',WebkitTextStroke: '1px blueviolet', });
 
    const animation = gsap.to(words3.current, {
      WebkitTextFillColor: 'red',
WebkitTextStroke: '2px purple',
     y:1300,
      ease: "power3.inOut",
      duration:5,
     scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ?"-140px": window.innerHeight >= 700 && window.innerHeight <= 800 ?"+=-30px": "+=120px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4000px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);
















const daytt = React.useRef()


  React.useEffect(() => {


    gsap.from(daytt.current, {y:0   });
 
    const animation = gsap.to(daytt.current, {
     y:1300,
          ease: "power3.inOut",
            duration:15.5,
      scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ? "+=-160px":window.innerHeight >= 700 && window.innerHeight <= 800 ?"+=-30px": "+=120px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4000px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });




 
    return () => {
      animation.kill();
    };
  }, []);










  const dayttt = React.useRef()


  React.useEffect(() => {


    gsap.from(dayttt.current, {y:0   });
 
    const animation = gsap.to(dayttt.current, {
     y:1300,
          ease: "power3.inOut",
            duration:15.5,
      scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ? "+=-160px":window.innerHeight >= 700 && window.innerHeight <= 800 ?"+=-30px":"+=120px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4000px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });




 
    return () => {
      animation.kill();
    };
  }, []);







  React.useEffect(() => {


    gsap.from(dayt.current, {y:0   });
 
    const animation = gsap.to(dayt.current, {
     y:window.innerWidth >= 900 && window.innerWidth <= 1005 ? 1500: window.innerWidth >= 700 && window.innerWidth <= 899 ? 1600: window.innerWidth >= 700 && window.innerWidth <= 899 ? 1600:  window.innerWidth >= 650 && window.innerWidth <= 700? 1700: window.innerWidth >= 600 && window.innerWidth <= 650? 1950: window.innerWidth >= 550 && window.innerWidth <= 600? 1950: window.innerWidth >= 400 && window.innerWidth <= 500? 2150: window.innerWidth >= 550 && window.innerWidth <= 600? 1950: window.innerWidth >= 300 && window.innerWidth <= 400? 2150:1300,
          ease: "power3.inOut",
            duration:5.5,
      scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ? "+=-220px": window.innerWidth >= 700 && window.innerWidth <= 1005 ? "+=20px": window.innerHeight >= 700 && window.innerHeight <= 800 ?"+=160px": "+=20px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4000px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });




 
    return () => {
      animation.kill();
    };
  }, []);




  const smokt = React.useRef()




  React.useEffect(() => {


    gsap.from(smokt.current, {y:0,background:'blue'   });
 
    const animation = gsap.to(smokt.current, {
     y:1300,
     background:'red',
          ease: "power3.inOut",
            duration:13.5,
      scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ? "+=-180px":"+=0px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=4000px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });




 
    return () => {
      animation.kill();
    };
  }, []);







  const first = React.useRef();
  const secound = React.useRef();






  React.useEffect(() => {


    gsap.from(first.current, {y:0   });
 
    const animation = gsap.to(first.current, {
     y:-600,
      ease: "power3.inOut",
      scrollTrigger: {
        start:  window.innerWidth >= 1000 && window.innerWidth <= 1325 ? "+=1000px":"+=1530px", // Start the animation when the trigger is at the vertical center of the viewport
        end: window.innerWidth >= 1000 && window.innerWidth <= 1325 ? "+=1500px": '+=1730px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 0.5,
      },
     
    });
 






 
    return () => {
      animation.kill();
    };
  }, []);





  React.useEffect(() => {


    gsap.from(secound.current, {y:0   });
 
    const animation = gsap.to(secound.current, {
     y:-600,
      ease: "power3.inOut",
      scrollTrigger: {
        start: window.innerWidth >= 1000 && window.innerWidth <= 1325 ? "+=1600px": "+=1750px", // Start the animation when the trigger is at the vertical center of the viewport
        end: window.innerWidth >= 1000 && window.innerWidth <= 1325 ? "+=1800px": '+=1950px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 0.5,
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);

















  const wunt = React.useRef();


  React.useEffect(() => {
    gsap.set(wunt.current, { y: -50, });
 
    const animation = gsap.to(wunt.current, {
      y: 550,
      duration:1,
      ease: "power3.inOut",
      scrollTrigger: {
        start: '+=4000px', // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=5500px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
        toggleActions: "play none none none",
        immediateRender: false,
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);
 







  const meat = React.useRef();


  React.useEffect(() => {
    gsap.set(meat.current, { y: 10 });


    const animation = gsap.to(meat.current, {
      rotate:50,
      y: 905,
      x:30,
      duration: 13, // Increase the duration for a slower animation
      ease: 'slow(0.01, 0.01, false)', // Use a custom easing function for smoother motion
      scrollTrigger: {
        start: 'top top', // Element stays in the same position until it reaches the top of the viewport
        end: '+=1400px', // Move the element when scrolling down by 1400px
        scrub: 1,
        toggleActions: 'play none none none',
        immediateRender: false,
      },
    });


    return () => {
      animation.kill();
    };
  }, []);




  const rsz = React.useRef();


  React.useEffect(() => {
    gsap.set(rsz.current, { y: 10 });


    const animation = gsap.to(rsz.current, {
      y: 905,
      duration: 13, // Increase the duration for a slower animation
      ease: 'slow(0.01, 0.01, false)', // Use a custom easing function for smoother motion
      scrollTrigger: {
        trigger: rsz.current,
        start: 'top top', // Element stays in the same position until it reaches the top of the viewport
        end: '+=1400px', // Move the element when scrolling down by 1400px
        scrub: 1,
      },
    });
    return () => {
      animation.kill();
    };


  })





  const wuntt = React.useRef();


  React.useEffect(() => {
    gsap.set(wuntt.current, { y: 10, });
 
    const animation = gsap.to(wuntt.current, {
      y: -410,
      ease: "power3.inOut",
      scrollTrigger: {
        start: '+=6000px', // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=6300px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
        toggleActions: "play none none none",
        immediateRender: false,
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);
 








  const [progress, setProgress] = React.useState(0);
  const [done, setDone] = React.useState(false);






  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((b4progress) => {
   
        const diff = Math.random() * 10;
        const newProgress = Math.min(b4progress + diff, 100);


        if (newProgress === 100) {
          setDone(true);
        }


        return newProgress;
      });
    }, 200);
   


    return () => {
      clearInterval(timer);
    };
  }, []);





const textRef = React.useRef();


React.useLayoutEffect(() => {
  gsap.from(textRef.current, {
    opacity: 0,
    x:0,
    y:0,
  });


  const animation = gsap.to(textRef.current, {
    duration: 3,
    scrub: 0.2,
    delay:1,
    opacity: 1,
    y:100,
    x:200,
    scrollTrigger: {
      trigger: textRef.current,
      start: "top 90%",
      end: "bottom 10%",
      scrub: true,
    },
  });


  return () => {
    animation.kill();
  };
}, []);





























  const videoRef = React.useRef();

  const ust = React.useRef();











  React.useEffect(() => {


    gsap.from(ust.current, { opacity:0,   yPercent:0,   });
 
    const animation = gsap.to(ust.current, {
      opacity:1,
                  ease: "power3.inOut",
            duration:3,
      scrollTrigger: {
        start: "+=8050px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=300px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    gsap.to(ust.current, {
      yPercent:390,
      ease: 'none',
            duration:16,
      scrollTrigger: {
        start: "+=7710px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=1900px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);


















  const use = React.useRef();











  React.useEffect(() => {


    gsap.from(use.current, { opacity:0,yPercent:0,  });
 
    const animation = gsap.to(use.current, {
      opacity:1,
                  ease: "power3.inOut",
            duration:3,
      scrollTrigger: {
        start: "+=8450px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=400px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    gsap.to(use.current, {
      yPercent:400,
      ease: 'none',
            duration:16,
      scrollTrigger: {
        start: "+=7810px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=1900px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);
















  


  React.useEffect(() => {


    gsap.from(videoRef.current, { opacity:1,   y:0,       yPercent:0,      filter: 'blur(0px)',  });
 
    const animation = gsap.to(videoRef.current, {
      opacity:0.7,
                  ease: "power3.inOut",
            duration:3,
      scrollTrigger: {
        start: "+=6450px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=400px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    gsap.to(videoRef.current, {
      yPercent:325,
      ease: 'none',
            duration:16,
      scrollTrigger: {
        start: "+=7650px", // Start the animation when the trigger is at the vertical center of the viewport
        end: '+=1900px', // End the animation when the trigger is 20% from the bottom of the viewport
        scrub: 1,
      },
    });
 
    return () => {
      animation.kill();
    };
  }, []);

React.useEffect(() => {
  // Ensure that the video is loaded before attempting to pause
  if (videoRef.current && videoRef.current.readyState >= 2) {
    videoRef.current.pause();
  }
}, []);

// Adjust this speed factor to control the scrubbing speed
const speedFactor = 0.95;

// Scrub through the video on scroll
scroll((progress) => {
  if (videoRef.current && videoRef.current.readyState >= 2) {
    // Adjusting the progress to slow down the scrubbing
    const adjustedProgress = progress * speedFactor;
    videoRef.current.currentTime = videoRef.current.duration * adjustedProgress;
  }
});

  













const vint = React.useRef();






React.useEffect(() => {


  gsap.from(vint.current, {scale:0.7, y:0, opacity:1,objectFit:'fill' });

  const animation = gsap.to(vint.current, {
    scale:1.1,
    ease: "power3.inOut",
    scrollTrigger: {
      start: "+=8850px", // Start the animation when the trigger is at the vertical center of the viewport
      end: '+=1300px', // End the animation when the trigger is 20% from the bottom of the viewport
      scrub: 1,
    },
  });

  return () => {
    animation.kill();
  };
}, []);


const coords = { x: 0, y: 0 };
const circles = document.querySelectorAll(".circle");

const colors = ['white']

circles.forEach(function (circle, index) {
  circle.x = 0;
  circle.y = 0;
  circle.style.backgroundColor = colors[index % colors.length];
});

window.addEventListener("mousemove", function(e){
  coords.x = e.clientX;
  coords.y = e.clientY;
  
});

function animateCircles() {
  
  let x = coords.x;
  let y = coords.y;
  
  circles.forEach(function (circle, index) {
    circle.style.left = x - 12 + "px";
    circle.style.top = y - 12 + "px";
    
    circle.style.scale = (circles.length - index) / circles.length;
    
    circle.x = x;
    circle.y = y;

    const nextCircle = circles[index + 1] || circles[0];
    x += (nextCircle.x - x) * 0.3;
    y += (nextCircle.y - y) * 0.3;
  });
 
  requestAnimationFrame(animateCircles);
}

animateCircles();


  return (
    <div className="App">





<motion.div initial = {{y:0}} animate = {{y:-1200}} transition = {{delay:4, duration:1.5}} className = 'fz1'/>
<motion.div initial = {{y:0}} animate = {{y:-1200}} transition = {{delay:4, duration:1.6}} className = 'fz2'/>
<motion.div initial = {{y:0}} animate = {{y:-1200}} transition = {{delay:4,duration:1.7}} className = 'fz3'/>
<motion.div initial = {{y:0}} animate = {{y:-1200}} transition = {{delay:4, duration:1.8}} className = 'fz4'/>
<motion.div initial = {{y:0}} animate = {{y:-1200}} transition = {{delay:4, duration:1.9}} className = 'fz5'/>
<motion.div initial = {{y:0}} animate = {{y:-1200}} transition = {{delay:4,duration:2}} className = 'fz6'/>
<motion.div initial = {{y:0}} animate = {{y:-1200}} transition = {{delay:4, duration:2.1}} className = 'fz7'/>

<motion.div initial = {{y:0}} animate = {{y:-1200}} transition = {{delay:4,  duration:2.2}}  className = 'fz66'/>
<motion.div initial = {{y:0}} animate = {{y:-1200}} transition = {{delay:4, duration:2.3}}  className = 'fz55' />
<motion.div  initial = {{y:0}} animate = {{y:-1200}} transition = {{delay:4, duration:2.4}}   className = 'fz44' />
<motion.div initial = {{y:0}} animate = {{y:-1200}} transition = {{delay:4, duration:2.5}}  className = 'fz33'/>
<motion.div initial = {{y:0}} animate = {{y:-1200}} transition = {{delay:4, duration:2.6}}  className = 'fz22'/>
<motion.div initial = {{y:0}} animate = {{y:-1200}} transition = {{delay:4,duration:2.7}}  className = 'fz11'/>




<motion.img style = {{height:'200px', width:'250px', position:'fixed', }} className = 'ZO' initial = {{opacity:0}} animate = {{opacity:[1,1,1,0.9,0.7,0.5,0.3,0]}} transition = {{delay:1, duration:2.8}} src = {ZE}/>








<motion.div>














    <motion.img className = 'Professional'  transition = {{delay:2}} src = {Professional} />
    <motion.div   transition = {{delay:2,duration:2}} >
    <motion.img className = 'Wheel' animate = {{rotate:720}}  transition = {{delay:2,repeat:Infinity,duration:30}} src = {Wheel} whileDrag={{ scale: 1.2 }} whileFocus = {{cursor:'grab'}} whileHover = {{cursor:'grab'}} drag = 'y' dragConstraints = {{
right:210,
left:210,
top:20,
bottom:20
}} />




















<div ref = {back} className = 'backt' style = {{  zIndex:5, height:'550vh', position:'absolute', top:'150vh',left:'0vw', width:'100vw', }}/>


<video src = {Zont}  typeof="video/mp4" ref = {vint} className = 'vidt' style = {{position:'absolute', zIndex:8, }} muted autoPlay loop />




<a href = "https://www.instagram.com/sub3ih/"  target="_blank" >


<i  ref = {inst} style = {{position:'fixed',  zIndex:25,color:'blue',opacity:1}}  class="fa-brands fa-instagram"></i></a>

<a href = "https://twitter.com/Sub3ih"  target="_blank" >
<i ref = {whats}  style = {{position:'fixed', zIndex:25,color:'blue',opacity:1}} class="fa-brands fa-x-twitter"></i>
</a>

<div ref = {smoke} style = {{padding: '65px', filter:'blur(72px)',top:'13vh',left:'7vw',position:'fixed',borderRadius:'100%',zIndex:22,opacity:1}} />


<h3 className = 'h' ref = {champ} style = {{  position:'fixed', fontFamily:'Aktura',WebkitTextFillColor: 'blue',WebkitTextStroke: '0.5px blueviolet',zIndex:25,cursor:'default'}}>Home</h3>
<h3  ref = {champ1} className = 'ha'  style = {{opacity:0.2,position:'fixed', color:'purple',  fontFamily:'Aktura',WebkitTextFillColor: 'blue',WebkitTextStroke: '0.5px blueviolet',zIndex:25,cursor:'default'}}>About</h3>
<h3 ref = {champ2}  className = 'hac'   style = {{position:'fixed',  color:'purple', opacity:'20%', fontFamily:'Aktura',WebkitTextFillColor: 'blue',WebkitTextStroke: '0.5px blueviolet',zIndex:25,cursor:'default'}}>Contact</h3>






<Canvas className='meat' ref = {meat} camera = {{fov:45}} style = {{position:'absolute',height:'500px', width:'400px', overflow:'visible',transform:'rotate(-40deg)'}}>




<directionalLight intensity = {1}/>
      <ambientLight intensity = {5}/>


<Meatride scale = {0.0046}/>


</Canvas>








<motion.div >
 
  <div >
    <h3 className = 'at' ref = {words} style = {{position:'absolute',  fontFamily:'Aktura', fontWeight:100,zIndex:6,cursor:'default'}}>About Me:</h3>
    <p className = 'wordst' ref = {words1} style = {{ fontFamily:'Aktura',position:'absolute',fontWeight:100,zIndex:6,cursor:'default'}}>"<span style = {{WebkitTextFillColor: 'blue'}}>Worlds Greatest 3D ReactJS Developer</span>, Whom Forges Unique & Jawbreaking Status Designs, Ranging From Ecommerce To Landing Pages " - <span style = {{WebkitTextStroke: '1px red', WebkitTextFillColor:'blue'}}>RS</span></p>
    </div>




    <img className = 'stz' ref = {words2}  style = {{position:'absolute',zIndex:6, height:'150px', width:'160px'}} src = {s}/>
<div style = {{position:'absolute',zIndex:6,}} className = 'words33' >
<i ref = {words3}  style = {{ fontSize:'38px', color:'purple'}} class="fa-solid fa-arrow-down-long fa-fade"></i>
</div>


<h1  style = {{cursor:'default'}}  className = 'tope' >Professional</h1>
<h1 style = {{cursor:'default'}}  className = 'topee' >Beyond Ai</h1>

<h1  style = {{cursor:'default'}}  className = 'topt' >Emperio</h1>

<h1  style = {{cursor:'default'}}  className = 'toptt' >ReactJS</h1>
<h1 style = {{cursor:'default'}}   className = 'topttt' >Developer</h1>






<div ref = {smokt} style = {{padding: '165px', filter:'blur(221px)',top:'180vh',right:'17vw',position:'absolute',borderRadius:'10%',zIndex:7,opacity:'40%' }} />


<Canvas ref = {dayt} camera = {{fov:75}} className = "dayatona" style = {{ position:'absolute',zIndex:7, overflow:'visible'}}>




<directionalLight intensity = {2}/>
      <ambientLight intensity = {2}/>


<Mercl scale = {0.8}/>


</Canvas>








<Canvas className = "Fem" ref = {daytt} camera = {{fov:75}} style = {{height:'600px', width:'650px', position:'absolute',zIndex:7, overflow:'visible'}}>




<directionalLight intensity = {0.5}/>
      <ambientLight intensity = {0.5}/>


<Female scale = {0.7}/>


</Canvas>




<div ref = {square} style = {{position:'absolute', zIndex:6, }} className = 'line'/>
<div ref = {line} style = {{position:'absolute', zIndex:5, }} className = 'linee'/>

<Canvas className = 'Me' ref = {dayttt}  camera = {{fov:75}} style = {{height:'600px', width:'650px', position:'absolute',zIndex:7, overflow:'visible'}}>




<directionalLight intensity = {0.5}/>
      <ambientLight intensity = {0.5}/>


<Men scale = {0.7}/>


</Canvas>

<video src = {vo} ref = {emperious} typeof="video/mp4"  className = 'vot' style = {{position:'absolute', zIndex:4, borderRadius:'10px',}} muted autoPlay loop />
<p className = 'swyy' style = {{position:'absolute'}}>Emperious, Magnificient, Optimum, Unmatched, Perpetual Multi-Disciplinary Developer, Entrepreneur , Freelancer, Magnus Professional Whom Crafts Million Dollar Designs.</p>

<h1 className = 'swy' style = {{position:'absolute'}} ><a className = "ape">Emperious Design</a></h1>


<video src = {finger}  ref = {videoRef} typeof="video/mp4"  className = 'finger' style = {{position:'absolute', zIndex:8,}}  muted playsinline preload loop />
<h1 ref = {ust} className = 'us' style = {{position:'absolute', zIndex:9}}>US YOU</h1>
<h1 ref = {use} className = 'use' style = {{position:'absolute', zIndex:9,}}>GLORY</h1>

<Canvas className = 'Emp'  camera = {{fov:75}} style = {{height:'600px', width:'650px', position:'absolute',zIndex:6, overflow:'visible'}}>



<directionalLight intensity = {2}/>
      <ambientLight intensity = {2}/>


      <Emperio  scale = {1} />

</Canvas>













<a href = "https://docs.google.com/document/d/1cXTg5dIfpflu1_XtaUFwLbKppWcK00yWUQJyfGpn8Wc/edit"  target="_blank" ><h1 className = 'credi'>Credits</h1></a>
















<Canvas ref = {savage} className = 'Sauvage'  camera = {{fov:75}} style = {{height:'600px', width:'650px', position:'absolute',zIndex:6, overflow:'visible'}}>



<directionalLight intensity = {21}/>
      <ambientLight intensity = {21}/>


      <DioDio  scale = {21} />

</Canvas>



<Canvas  ref = {bdc} className = 'chpain'  camera = {{fov:75}} style = {{height:'600px', width:'650px', position:'absolute',zIndex:6, overflow:'visible'}}>


<directionalLight intensity = {21}/>
      <ambientLight intensity = {31}/>


      <Chanel  scale = {0.5} />

</Canvas>


<h1 className = 'right' ref = {magnif} style = {{ zIndex:7,position:'absolute', fontFamily:'britney', color:'white', whiteSpace:'nowrap',cursor:'default'}}>-We Produce: Magnificient, <span style = {{color:'darkblue'}}> Responsive</span> ,<span style = {{color:'darkblue'}}> Professional</span>, 3D Websites</h1>



</motion.div>


<div ref = {rsz}>










<h1 className = 'Finish' style = {{position:'absolute',cursor:'default'}}>We Eagerly Await Your  </h1>
<h1 className = 'Finishh' style = {{position:'absolute',cursor:'default'}}>Email U$ </h1>



<form action="https://formsubmit.co/rayanbrogo@gmail.com" method="POST" >
<input name = "Good Name:" style = {{position:'absolute',zIndex:15, background:'none', border:'none',borderBottom:'solid 1px #4b0126',outline:'none', color:'red',  fontFamily:'Aktura'}} type = 'Text'  placeholder='Name' className = 'name' required/>
<input name = "Offer:" style = {{position:'absolute',zIndex:15, background:'none', border:'none',borderBottom:'solid 1px #4b0126',outline:'none', color:'white',fontFamily:'britney',}} type = 'Text'  placeholder='Message/Offer' className = 'namee' required/>

<button type = "submit" style = {{position:'absolute',zIndex:15,}} className = 'button-85'>Send</button>

</form>








<div className="shadowBox">
<h1 className="rainbow rainbow_text_animated" style = {{position:'absolute',zIndex:8,cursor:'default'}}>Alliance:</h1>
</div>
<Canvas className = 'war' camera = {{fov:75}} style = {{height:'3500px', width:'3500px', position:'absolute',overflow:'visible', zIndex:7}}>




<directionalLight intensity = {1}/>
      <ambientLight intensity = {1}/>


<War scale = {0.002}/>


</Canvas>

<span ref = {moust} class="scroll-btn">
		<span class="mouse">
			<span>
			</span>
		</span>


</span>


<img style = {{ position:'absolute'}} className = 'Rss'  src = {Rss}/>
<motion.img className = 'jely'  style = {{ position:'absolute',}} src = {RJelly}/>
<motion.img initial = {{opacity:1}} animate = {{opacity:[1,0.8,0.7,0.5,0.7,0.9,1]}}  transition = {{duration:2, ease:'linear', repeat:Infinity}}  className = 'sigma'  style = {{ position:'absolute', }} src = {RSigma}/>




</div>






</motion.div>




    </motion.div>
    </div>
  );
}


export default App;
